


































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { logisticServices } from "@/services/logistic.service";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { purchaseRequisitionServices } from "@/services/purchaseRequisition.service";
import moment from "moment";
import { ListPurchaseRequisitionData } from "@/models/interface/purchaseRequisition.interface";
import { contactServices } from "@/services/contact.service";
@Component
export default class ListPurchaseRequisition extends Vue {
  currentPage = 1 as number;
  modalOpen = false as boolean;
  limit = 10;
  page = 0;
  totalData = 0 as number;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = this.$form.createForm(this, { name: "purchaseRequisition" });
  formModal = this.$form.createForm(this, { name: "purchaseRequisitionModal" });
  downloadParam = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    branch: "ALL",
    requester: "ALL",
    dateFrom: "ALL",
    dateTo: "ALL",
    purchaseOrderNumber: "ALL",
    status: "ALL",
    requestType: "ALL",
  };
  searchData = "" as any;
  formRulesModal = {
    status: {
      label: this.$t("lbl_reason_close_pr"),
      decorator: ["reasonToClosePr"],
    },
  };
  columnsTable = [
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branch",
      scopedSlots: { customRender: "isNull" },
      width: 150,
    },
    {
      title: this.$t("lbl_purchase_requisition_number"),
      dataIndex: "documentNo",
      key: "purchaseRequisitionNumber",
      scopedSlots: { customRender: "isNull" },
      width: 300,
    },
    {
      title: this.$t("lbl_requester"),
      dataIndex: "requesterName",
      key: "requester",
      scopedSlots: { customRender: "isNull" },
      width: 200,
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "date",
      key: "date",
      scopedSlots: { customRender: "isDate" },
      width: 200,
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      scopedSlots: { customRender: "isNull" },
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 120,
      align: "center",
    },
  ] as ColumnTableCustom;
  dataList = [] as ListPurchaseRequisitionData[];
  formRules = {
    dateFrom: {
      decorator: ["dateFrom"],
    },
    requester: {
      decorator: ["requester"],
    },
    purchaseRequisitionNumber: {
      decorator: ["purchaseRequisitionNumber"],
    },
    status: {
      decorator: ["status"],
    },
    requestType: {
      decorator: ["requestType"],
    },
    dateTo: {
      decorator: ["dateTo"],
    },
    branch: {
      decorator: ["branch"],
    },
  };
  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
  dataBranch = [] as DataWarehouseBranch[];
  dataRequester = [] as any[];
  dataPurchaseRequisitionNumber = [] as ListPurchaseRequisitionData[];
  dataStatus = [] as string[];
  dataRequestType = [] as string[];
  loading = {
    branch: false as boolean,
    table: false as boolean,
    requester: false as boolean,
    purchaseRequisitionNumber: false as boolean,
    status: false as boolean,
    requestType: false as boolean,
    find: false as boolean,
    closePr: false as boolean,
    download: false as boolean,
  };
  responseViewTable(response) {
    this.$router.push(
      "/purchasing/purchaserequisition/edit/" + response.data.id
    );
  }
  handleBack() {
    this.form.resetFields();
    this.modalOpen = false;
  }
  onSubmit() {
    return;
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  findData(page): void {
    this.form.validateFields((err, value) => {
      if (!err) {
        const params: RequestQueryParamsModel = {
          page: page ? this.page : 0,
          limit: this.limit,
          sorts: "createdDate:desc",
        };
        for (const key in this.downloadParam) {
          if (key != "companyName") {
            this.downloadParam[key] = "ALL";
          }
        }
        if (this.dynamicSearch(value)) {
          this.searchData = this.dynamicSearch(value);
          params.search = this.dynamicSearch(value);
        }
        this.getData(params);
      }
    });
  }
  getRequester(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `employee~true_AND_active~true`,
    };
    if (value)
      params.search += `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    this.loading.requester = true;
    return contactServices
      .listContactData(params)
      .then(res => {
        this.dataRequester = res.data;
      })
      .finally(() => (this.loading.requester = false));
  }
  getDataDrodpdown(value, type) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      sorts: "createdDate:desc",
    };
    if (!value && type == "init") {
      this.loading.purchaseRequisitionNumber = true;
    } else {
      if (value && type == "purchaseRequisitionNumber") {
        this.loading.purchaseRequisitionNumber = true;
        params.search = `documentNo~*${value}*`;
      }
    }
    purchaseRequisitionServices
      .listPurchaseRequisition(params)
      .then(res => {
        if (type == "init") {
          this.dataPurchaseRequisitionNumber = res.data as [];
        } else {
          if (type == "purchaseRequisitionNumber")
            this.dataPurchaseRequisitionNumber = res.data as [];
        }
      })
      .finally(() => {
        if (type === "init") {
          this.loading.purchaseRequisitionNumber = false;
        } else {
          if (type == "purchaseRequisitionNumber") {
            this.loading.purchaseRequisitionNumber = false;
          }
        }
      });
  }
  getStatus() {
    purchaseRequisitionServices.listStatusPurchaseRequisition().then(res => {
      this.dataStatus = res;
    });
  }
  getRequestType() {
    purchaseRequisitionServices
      .listRequestTypePurchaseRequisition()
      .then(res => {
        this.dataRequestType = res;
      });
  }
  getData(params) {
    purchaseRequisitionServices.listPurchaseRequisition(params).then(res => {
      this.totalData = res.totalElements;
      this.dataList = res.data;
    });
  }
  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }
  assignSearch(key, value, operator): string {
    if (key === "branch" && value) {
      this.downloadParam.branch = this.dataBranch.find(item => item.id == value)
        ?.name as string;
      return operator + `branch.secureId~${value}`;
    } else if (key === "requester" && value) {
      this.downloadParam.requester = this.dataRequester.find(
        item => item.id == value
      )?.firstName as string;
      return operator + `requester.secureId~${value}`;
    } else if (key === "dateFrom" && value) {
      this.downloadParam.dateFrom = moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `date>=${moment(value)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .format()}`
      );
    } else if (key === "dateTo" && value) {
      this.downloadParam.dateTo = moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `date<=${moment(value)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .format()}`
      );
    } else if (key === "purchaseRequisitionNumber" && value) {
      this.downloadParam.purchaseOrderNumber = value;
      return operator + `documentNo~${value}`;
    } else if (key === "status" && value) {
      this.downloadParam.status = value;
      return operator + `status~${value.replace(" ", "_").toUpperCase()}`;
    } else if (key === "requestType" && value) {
      this.downloadParam.requestType = value;
      return operator + `poType~${value}`;
    } else return "";
  }
  clearItems(): void {
    this.form.resetFields();
  }
  CreateNew(): void {
    this.$router.push("/purchasing/purchaserequisition/create");
  }
  download() {
    let params = {
      params: `${this.downloadParam.companyName},${this.downloadParam.branch},${this.downloadParam.requester},${this.downloadParam.dateFrom},${this.downloadParam.dateTo},${this.downloadParam.purchaseOrderNumber},${this.downloadParam.status},${this.downloadParam.requestType}`,
    } as RequestQueryParamsModel;
    if (this.searchData) {
      params.search = this.searchData;
    }
    purchaseRequisitionServices
      .downloadPurchaseRequisition(params)
      .then(res => {
        if (res) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "purchase_requisition.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      });
  }
  mounted() {
    this.getBranch("");
    this.getDataDrodpdown("", "init");
    this.getRequester("");
    this.getStatus();
    this.getRequestType();
  }
  getBranch(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value)
      params.search = `name~*${value}*_OR_code~*${value}*_OR_address~*${value}`;
    this.loading.branch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.branch = false));
  }
  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 0;
    this.currentPage = 1;
    this.findData(true);
  }
  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page - 1;
    this.currentPage = response.page;
    this.findData(true);
  }
}
